import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Contexts
import { ThemeProvider } from "./context/ThemeContext";
import { NotificationProvider } from "./context/NotificationContext";

// Hooks
import useAuth from "./hooks/isAuth"; // Pastikan nama file mencerminkan isi/export nya
import PreloaderNavigation from "./hooks/PreloaderNavigation";
import useSocket from "./hooks/useSocket"; // Import hook kustom yang baru dibuat

// Components
const Header = lazy(() => import("./components/Header"));
const Sidebar = lazy(() => import("./components/Sidebar"));
const SidebarHorizontal = lazy(() => import("./components/Sidebar/horizontal"));
const Customer = lazy(() => import("./components/Customer"));
const NotFound = lazy(() => import("./components/NotFound"));
const SwitcherTheme = lazy(() => import("./components/Switcher Theme"));
const Preloader = lazy(() => import("./components/Preloader"));
const GreetingToast = lazy(() => import("./components/Toast/GreetingToast"));
const Notifications = lazy(() => import("./components/Notifications"));

// Pages
const Login = lazy(() => import("./pages/Login"));
const Home = lazy(() => import("./pages/Home"));
const RegisterPage = lazy(() => import("./pages/Register"));
const ForgotPasswordPage = lazy(() => import("./pages/ForgotPass"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPass"));
const VerificationPage = lazy(() => import("./pages/Verification"));
const LeadsPage = lazy(() => import("./pages/Leads"));
const Earning = lazy(() => import("./components/Earning"));
const WithdrawalPage = lazy(() => import("./components/Withdrawal"));

// Protected Route
const ProtectedRoute = lazy(() => import("./hooks/ProtectedRoute"));

// Layout
const BodyWrapperLayout = lazy(() => import("./components/Body Wrapper"));

function App() {
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  return (
    <Router>
      <ThemeProvider>
        <NotificationProvider>
          <Suspense fallback={<Preloader />}>
            <Main
              isAuthenticated={isAuthenticated}
              setIsAuthenticated={setIsAuthenticated}
            />
          </Suspense>
        </NotificationProvider>
      </ThemeProvider>
    </Router>
  );
}
function Main({ isAuthenticated, setIsAuthenticated }) {
  const isNavigating = PreloaderNavigation(); // Gunakan custom hook di dalam komponen yang dibungkus <Router>
  useSocket(isAuthenticated);
  return (
    <>
      {isNavigating && <Preloader />}
      <GreetingToast />
      <div id="main-wrapper">
        <Suspense fallback={<Preloader />}>
          {isAuthenticated && (
            <>
              <Sidebar setIsAuthenticated={setIsAuthenticated} />
              <SidebarHorizontal setIsAuthenticated={setIsAuthenticated} />
            </>
          )}
        </Suspense>
        <div className={isAuthenticated ? "page-wrapper" : ""}>
          {isAuthenticated && (
            <>
              <Header setIsAuthenticated={setIsAuthenticated} />
            </>
          )}
          <Routes>
            <Route
              path="/login"
              element={
                <Login
                  isAuthenticated={isAuthenticated}
                  setIsAuthenticated={setIsAuthenticated}
                />
              }
            />
            <Route
              path="/register"
              element={<RegisterPage isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/two-factor-auth/:token"
              element={
                <VerificationPage
                  isAuthenticated={isAuthenticated}
                  setIsAuthenticated={setIsAuthenticated}
                />
              }
            />
            <Route
              path="/auth/forgot-password"
              element={<ForgotPasswordPage isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/auth/reset-password/:token"
              element={<ResetPasswordPage isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <BodyWrapperLayout>
                    <Home />
                  </BodyWrapperLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/prospek-customer"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <BodyWrapperLayout>
                    <Customer />
                  </BodyWrapperLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/prospek-leads"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <BodyWrapperLayout>
                    <LeadsPage />
                  </BodyWrapperLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/rewards"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <BodyWrapperLayout>
                    <Earning />
                  </BodyWrapperLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/withdrawal"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <BodyWrapperLayout>
                    <WithdrawalPage />
                  </BodyWrapperLayout>
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        {isAuthenticated && <Notifications />}
        {isAuthenticated && <SwitcherTheme />}
      </div>
      <div className="dark-transparent sidebartoggler"></div>
    </>
  );
}
export default App;

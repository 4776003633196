import axios from "axios";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = `${apiDomain}/api`;

const getUserInfo = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      withCredentials: "include",
      url: `${URL}/users/info`,
    });
    return cb(result.data);
  } catch (error) {
    console.error("Error getting user info:", error);
    return error.response.data;
  }
};

export { getUserInfo };

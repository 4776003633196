import { useEffect } from "react";
import io from "socket.io-client";
import { getUserInfo } from "../../api/user";
import { useNotifications } from "../../context/NotificationContext";

// Sesuaikan dengan URL server Anda
const SOCKET_SERVER_URL = process.env.REACT_APP_API_DOMAIN;

const useSocket = (isAuthenticated) => {
  const { addNotification } = useNotifications();
  useEffect(() => {
    if (!isAuthenticated) return;

    const connectSocket = async () => {
      try {
        let userId;
        await getUserInfo((cb) => {
          userId = cb.data.id;
        });

        const socket = io(SOCKET_SERVER_URL);

        // Event handler untuk koneksi yang berhasil
        socket.on("connect", () => {
          console.log("Connected to socket server");
          // Emit 'register' event setelah terkoneksi
          socket.emit("register", userId);
        });

        socket.on("notifLead", (notification) => {
          addNotification(notification.message);
        });

        socket.on("disconnect", () => {
          console.log("Disconnected from socket server");
        });

        // Cleanup function
        return () => {
          console.log("Disconnecting from socket");
          socket.disconnect();
        };
      } catch (error) {
        console.error("Failed to get user info:", error);
      }
    };

    connectSocket();

    //eslint-disable-next-line
  }, [isAuthenticated]); // Efek ini bergantung pada nilai isAuthenticated

};

export default useSocket;

import { useState, useEffect } from "react";
import { verifyAuthentication } from "./../../api/auth";

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  useEffect(() => {
    const verifyAuth = async () => {
      try {
        const authStatus = await verifyAuthentication();
        setIsAuthenticated(authStatus.isAuthenticated);
      } catch (error) {
        console.error("Authentication error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    verifyAuth();
  }, []);

  return { isAuthenticated, isLoading , setIsAuthenticated};
};

export default useAuth;

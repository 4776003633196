// ThemeContext.js
import React, { createContext, useState, useEffect } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");
  const [colorTheme, setColorTheme] = useState("Blue_Theme");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    document.documentElement.setAttribute("data-bs-theme", theme);
    document.documentElement.setAttribute("data-color-theme", colorTheme);
    document.body.setAttribute("data-sidebartype", isSidebarOpen ? "mini-sidebar" : "full");
    document.getElementById("main-wrapper").classList.toggle("show-sidebar", isSidebarOpen);
    
  }, [theme, colorTheme, isSidebarOpen]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <ThemeContext.Provider
      value={{ theme, setTheme, colorTheme, setColorTheme, isSidebarOpen,setIsSidebarOpen, toggleSidebar }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;

import axios from "axios";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = `${apiDomain}/api`;

const verifyAuthentication = async () => {
  try {
    let result = await axios({
      method: "GET",
      withCredentials: "include",
      url: `${URL}/check`,
    });
    return result.data;
  } catch (error) {
    console.error("Error checking authentication:", error);
    return error.response.data;
  }
};

const authGetCsrfToken = async () => {
  try {
    let result = await axios({
      method: "GET",
      url: `${URL}/csrf-token`,
    });
    return result.data;
  } catch (error) {
    console.error("Error getting csrf token:", error);
    return error.response.data;
  }
};
const authRegister = async (data, _csrf) => {
  try {
    const result = await axios({
      method: "POST",
      url: `${URL}/register`,
      withCredentials: "include",
      headers: {
        "X-CSRF-Token": _csrf,
        "Content-Type": "application/json",
      },
      data,
    });
    return result.data;
  } catch (error) {
    console.error("Error register:", error);
    return error.response;
  }
};
const authLogin = async (data, _csrf) => {
  try {
    const result = await axios({
      method: "POST",
      url: `${URL}/login`,
      withCredentials: "include",
      headers: {
        "X-CSRF-Token": _csrf,
        "Content-Type": "application/json",
      },
      data,
    });
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.error("Error login:", error);
    return error.response.data;
  }
};

const authLogout = async () => {
  try {
    let result = await axios({
      method: "POST",
      withCredentials: "include",
      url: `${URL}/logout`,
    });
    return result.data;
  } catch (error) {
    console.error("Error logout:", error);
    return error.response.data;
  }
};
const authVerifyUserToken = async (data, _csrf) => {
  try {
    let result = await axios({
      method: "POST",
      url: `${URL}/verify-user`,
      withCredentials: true,
      headers: {
        "X-CSRF-Token": _csrf,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data), // Pastikan untuk mengirim data sebagai string JSON
    });
    return result.data;
  } catch (error) {
    console.error("Error verify user token:", error);
    return error.response.data;
  }
};

const resendVerificationCode = async (data, _csrf) => {
  try {
    let result = await axios({
      method: "POST",
      url: `${URL}/resend-verify`,
      withCredentials: true,
      headers: {
        "X-CSRF-Token": _csrf,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data), // Pastikan untuk mengirim data sebagai string JSON
    });
    return result.data;
  } catch (error) {
    console.error("Error resend verification code:", error);
    return error.response.data;
  }
};

const authverificationEmailCode = async (data, _csrf) => {
  try {
    let result = await axios({
      method: "POST",
      url: `${URL}/verify-email`,
      withCredentials: true,
      headers: {
        "X-CSRF-Token": _csrf,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
    return result.data; // Asumsi bahwa server mengembalikan data dalam format yang tepat
  } catch (error) {
    console.error("Error during email verification:", error);
    if (error.response) {
      return error.response.data;
    } else {
      // Jika error tidak mengandung respons, kembalikan pesan error generik
      return { status: 500, message: "Network Error or Server Unreachable" };
    }
  }
};

const authForgotPassword = async (data, _csrf) => {
  try {
    let result = await axios({
      method: "POST",
      url: `${URL}/forgot-password`,
      withCredentials: true,
      headers: {
        "X-CSRF-Token": _csrf,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
    return result.data;
  } catch (error) {
    console.error("Error during password reset:", error);
    if (error.response) {
      return error.response.data;
    } else {
      // Jika error tidak mengandung respons, kembalikan pesan error generik
      return { status: 500, message: "Network Error or Server Unreachable" };
    }
  }
};

const authVerifyTokenResetPassword = async (data, _csrf) => {
  try {
    let result = await axios({
      method: "POST",
      url: `${URL}/verify-reset-password/${data}`,
      withCredentials: true,
      headers: {
        "X-CSRF-Token": _csrf,
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    console.error("Error during token verification:", error);
    if (error.response) {
      return error.response.data;
    } else {
      // Jika error tidak mengandung respons, kembalikan pesan error generik
      return { status: 500, message: "Network Error or Server Unreachable" };
    }
  }
};

const authResetPassword = async (data, _csrf) => {
  try {
    let result = await axios({
      method: "POST",
      url: `${URL}/reset-password`,
      withCredentials: true,
      headers: {
        "X-CSRF-Token": _csrf,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
    return result.data;
  } catch (error) {
    console.error("Error during password reset:", error);
    if (error.response) {
      return error.response.data;
    } else {
      // Jika error tidak mengandung respons, kembalikan pesan error generik
      return { status: 500, message: "Network Error or Server Unreachable" };
    }
  }
};

export {
  verifyAuthentication,
  authRegister,
  authLogin,
  authGetCsrfToken,
  authLogout,
  authVerifyUserToken,
  resendVerificationCode,
  authverificationEmailCode,
  authForgotPassword,
  authVerifyTokenResetPassword,
  authResetPassword,
};

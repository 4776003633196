import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const PreloaderNavigation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);

    const handleComplete = () => setIsLoading(false);

    // Menunggu transisi halaman selesai
    setTimeout(handleComplete, 1000);

    return () => {
      clearTimeout(handleComplete);
    };
  }, [location.pathname]); // Bergantung pada perubahan lokasi

  return isLoading;
};

export default PreloaderNavigation;

import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/styles.css";
import App from "./App";

import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "simplebar/dist/simplebar.min.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
